import React, { useState, useEffect, useContext, useRef } from "react";
import { errorToaster, handleKeypress, successToaster } from "../../../helpers/utils";
import { validateDiscountCode } from "../../../crud/checkout.crud";
import Checkmark from "../../Checkout/RegistrationComponents/SubComponents/Checkmark";
import eventContext from "../../../contexts/EventContext";

const CouponInput = ({
	getCouponDetails,
	removeCoupon,
	colour,
	newQuery,
	setIsAccessCode,
	isAccessCode,
	...props
}) => {
	const [couponCode, setCouponCode] = useState("");
	const [apiLoading, setApiLoading] = useState(false);
	const [hasCouponCode, setHasCouponCode] = useState(false);
	const [pasted, setPasted] = useState(false);
	const { eventData } = useContext(eventContext);
	const hasMountedOnce = useRef(false);

	const couponRegex = /^[a-zA-Z0-9@&#$!()*-]+$/;

	const validateCoupon = async (couponCode) => {
		if (couponCode.trim() !== "") {
			if (!couponRegex.test(couponCode)) {
				errorToaster(
					"Only alphanumeric characters and special characters @ & # $ ! ( ) - * are allowed",
				);
				return false;
			} else {
				setApiLoading(true);
				try {
					let res = await validateDiscountCode(
						eventData.event_id,
						encodeURIComponent(couponCode),
					);
					const couponDetails = res.data;
					const statusCode = res.status;
					if (statusCode === 200) {
						const isAccessCode = couponDetails?.tickets?.length > 0 ? true : false;
						if (isAccessCode && couponDetails?.tickets?.some(each => each?.team_registration)) {
							setHasCouponCode(true);
							setIsAccessCode(true);
							successToaster("Access Code applied successfully! Tickets are available under Team Based Registration")
							getCouponDetails(false, couponCode, couponDetails?.tickets);
							setApiLoading(false)
							return;
						}
						if (!isAccessCode) {
							if (couponDetails.availability === 1) {
								setHasCouponCode(true);
								getCouponDetails(couponDetails, couponCode);
							} else if (couponDetails.availabilty === 2) {
								errorToaster(
									"The coupon code you've entered has been exhausted.",
								);
							}
						} else {
							setHasCouponCode(true);
							setIsAccessCode(true);
							getCouponDetails(false, couponCode, couponDetails?.tickets);
						}
					} else {
						errorToaster("The coupon code you've entered is not available.");
					}
					setApiLoading(false);
				} catch (err) {
					console.log(err);
					errorToaster(err?.response?.data?.error, "coupon-id");
					setApiLoading(false);
				}
			}
		} else {
			errorToaster("Please enter a valid Access/Discount Code");
		}
	};

	const resetCoupon = () => {
		setHasCouponCode(false);
		setCouponCode("");
		// successToaster("Coupon code removed.");
		removeCoupon(isAccessCode);
		if (isAccessCode) setIsAccessCode(false);
	};

	const handlePaste = (value) => {
		if (!couponRegex.test(value)) {
			errorToaster(
				"The Access/Discount Code you are trying to paste contains special characters which are not allowed.",
			);
			setCouponCode("");
			setPasted(false);
		}
	};

	const regex = {
		allowAlphaNumeric: (evt) => {
			let charCode = String.fromCharCode(evt.which);
			if (!/^[a-z0-9-]+/i.test(charCode)) {
				evt.preventDefault();
			}
			return;
		},
		allowAlphaNumericAndSpecial: (evt) => {
			//alphanumeric and accpet special characters :- @, &, #, $, !, (, ), *
			let charCode = String.fromCharCode(evt.which);
			if (!couponRegex.test(charCode)) {
				evt.preventDefault();
			}
			return;
		},
	};

	const handleCouponCodeInput = (e) => {
		regex.allowAlphaNumericAndSpecial(e);
		if (e.charCode === 13) validateCoupon(couponCode);
	};

	const toInputUppercase = (e) => {
		e.target.value = ("" + e.target.value).toUpperCase();
	};

	useEffect(() => {
		if (pasted && couponCode.trim() !== "") {
			handlePaste(couponCode);
		}
	}, [pasted, couponCode]);

	//if coupon code is already applied from query
	useEffect(() => {
		if (newQuery.selectedCode && !hasMountedOnce.current) {
			let newCouponCode = newQuery.selectedCode;
			setCouponCode(newCouponCode);
			validateCoupon(newCouponCode);
			hasMountedOnce.current = true;
		}
	}, [newQuery]);
	return (
		<>
			<div className="w-100 mt-2 me-sm-3 me-0 d-flex my-3 flex-column justify-content-center align-items-center text-center">

				{/* <p>Enter discount/access code to view hidden tickets and discounts.</p> */}

				<div className="position-relative coupon-inp-container d-inline text-start text-sm-center">

					<input
						className="ps-3"
						disabled={hasCouponCode}
						placeholder="Enter Code"
						onInput={toInputUppercase}
						value={couponCode}
						onChange={(e) => {
							setCouponCode(e.target.value);
						}}
						onKeyPress={(e) => {
							handleCouponCodeInput(e);
						}}
						onPaste={(e) => {
							const str = e.clipboardData.getData("Text");
							if (str.trim() !== "") {
								setPasted(true);
							} else {
								setPasted(false);
							}
						}}
					></input>
					{hasCouponCode && (
						<div className="coupons-checkmark">
							<Checkmark
								size="16px"
								strokeWidth="6"
								color="#41c34a"
								query={{}}
							/>
						</div>
					)}
					{hasCouponCode ? (
						<button onClick={resetCoupon}>Remove</button>
					) : (
						<button
							disabled={!couponCode}
							onClick={() => validateCoupon(couponCode)}>
							{apiLoading ? (
								<div className="spinner-border small-spinner mt-1"></div>
							) : (
								"Apply"
							)}
						</button>
					)}
				</div>
			</div>
			<style jsx>
				{`
                input {
                    background: white;
                    border-radius: 5px;
                    border: 1px solid ${colour ? `${colour}` : "#FFDBA6"};
                    height: 55px;
                    width: 270px;
                    color: background: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    // letter-spacing: 2px;
                    transition: all .3s;
                }
                input:focus-visible,
                input:focus {
                    box-shadow: 1px 0px 3px ${colour ? `${colour}` : "#fb5850"};
                    outline: none;
                }
                button {
                    height: 55px;
                    color: #ffffff;
                    background: ${colour ? `${colour}` : "#fb5850"};
                    border-radius: 5px;
                    border: none;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 27px;
                    width:90px;
                    position: absolute;
                    right: -0px;
                    top: 0px;
                    transition: all .2s;
                }
                button:hover {
                    box-shadow: 2px 0px 10px ${colour ? `${colour}` : "#fb5850"};
                }
                .coupons-checkmark {
                    position: absolute;
                    right: 85px;
                    top: 15px;
                }
				button:disabled {
					background: #282828 !important;
					color:#ffffff !important
					cursor: not-allowed;
					box-shadow: none;
				}	


				@media screen and (max-width: 576px) {
					input , button {
						height:45px;
					}
					button{
						width: 30%;
						font-size: 14px;
						font-weight: 600;
						border-radius: 2px ;
					}
					input{
						width: 73%;
						font-size: 13px;
        				font-weight: 400;
					}
					.coupon-inp-container{
						width:100%;
					}
             }
                `}
			</style>
		</>
	);
};

export default CouponInput;
